<template>
    <span class="price-content">{{this.getOnlyPrice()}}</span>

</template>

<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-total',
    	props: {
    		pricelist: String,
    		unitid: String
    	},
    	data: function() {
    		return {
    			priceArray: {},
    			unitId: Number,
    			priceBookingFee: Number,
    			priceFinalCleaning: Number,
    			guestRangeMin: Number,
    			totalPrice: 0

    		};
    	},
    	mounted: function() {
    		var pricelist = JSON.parse(this.pricelist);

    		this.priceArray = pricelist;
    		//this.totalPrice = 0;


    	},
    	methods: {
    		getTotalPrice: function() {
    			//console.log("total price is==>" + this.totalPrice);

    			return this.totalPrice;
    		},
    		/* getPrice: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			console.log("pricelist length is==>" + pricelist.length);

    			var i, j;
    			var totalPriceValue = 0;
    			var totalPriceValueString;
    			for (i = 0; i < pricelist.length; i++) {
    				console.log("name is==>" + pricelist[i].service.name);
    				console.log("price  is==>" + pricelist[i].price);
    				if (pricelist[i].service.name == 'Buchungsgebühr' || pricelist[i].service.name == 'Endreinigung') {
    					totalPriceValue = totalPriceValue + pricelist[i].price;
    				}
    			}
    			totalPriceValueString = Number((totalPriceValue) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";
    			totalPriceValueString = this.voMsg('totalPriceText') + ' ' + totalPriceValueString;
    			return totalPriceValueString;
    		}, */

    		getOnlyPrice: function() {
    			var pricelist = JSON.parse(this.pricelist);

    			//console.log("pricelist length is==>" + pricelist.length);

    			var i, j;
    			var totalPriceValue = 0;
    			var totalPriceValueString;
    			for (i = 0; i < pricelist.length; i++) {
    				//console.log("name is==>" + pricelist[i].service.name);
    				//console.log("price  is==>" + pricelist[i].price);
    				//if (pricelist[i].service.name == 'Buchungsgebühr' || pricelist[i].service.name == 'Endreinigung') {
    				if (pricelist[i].service.type == 'BOOKING_FEE' || pricelist[i].service.type == 'FINALCLEANING') {
    					totalPriceValue = totalPriceValue + pricelist[i].price;
    				}
    			}

    			totalPriceValueString = Number((totalPriceValue) / 100).toLocaleString("de-DE", {
    				minimumFractionDigits: 2
    			}) + " €";

    			//console.log("totalPriceValueString", totalPriceValueString)

    			return totalPriceValueString;
    		},


    	},
    	computed: {
    		getPrice1: function() {
    			/*var pricelist = JSON.parse(this.pricelist);


    			var i, j;
    			for (i = 0; i < pricelist.length; i++) {
    				console.log("name is==>" + pricelist[i].name);
    			}*/
    			return 0;
    		}
    	}

    }
</script>